/**
 * @generated SignedSource<<c24d79df5e6c31902fde7261972234d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useZendeskLoaderAcoreUtilsFragment_query$data = {
  readonly company: {
    readonly csTier?: string | null;
    readonly id: string;
  };
  readonly " $fragmentType": "useZendeskLoaderAcoreUtilsFragment_query";
};
export type useZendeskLoaderAcoreUtilsFragment_query$key = {
  readonly " $data"?: useZendeskLoaderAcoreUtilsFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useZendeskLoaderAcoreUtilsFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "companyId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useZendeskLoaderAcoreUtilsFragment_query",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": "company",
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "csTier",
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "company"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b16df4d63fedd1f1a28f63f05d62b2a5";

export default node;
