import _fetchAccountPermissions_AcoreUtils_fragment_query from "./__generated__/fetchAccountPermissions_AcoreUtils_fragment_query.graphql";
import _fetchAccountPermissions_AcoreUtils_Query from "./__generated__/fetchAccountPermissions_AcoreUtils_Query.graphql";
import { graphql } from '@attentive/data';
import { fetchQuery, readInlineData } from 'react-relay';
import Query from './__generated__/fetchAccountPermissions_AcoreUtils_Query.graphql';
import Fragment from './__generated__/fetchAccountPermissions_AcoreUtils_fragment_query.graphql';
_fetchAccountPermissions_AcoreUtils_Query;
_fetchAccountPermissions_AcoreUtils_fragment_query;
export const fetchAccountPermissions = async _ref => {
  let {
    relayEnvironment,
    companyId
  } = _ref;
  const accountPermissionQueryRef = await fetchQuery(relayEnvironment, Query, {
    companyId
  }).toPromise();

  if (!accountPermissionQueryRef) {
    throw 'account permission query failed!';
  }

  return readInlineData(Fragment, accountPermissionQueryRef);
};