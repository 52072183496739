import { AccountPermissions } from '../RoleBasedAccessControl';
import {
  AccountPermissionDomainName,
  AccountPermissionFeatureName,
} from '../RoleBasedAccessControl/__generated__/fetchAccountPermissions_AcoreUtils_Query.graphql';

export const createAccountPermissions = ({
  featureName,
  domainName,
  createVal,
  readVal,
  updateVal,
  deleteVal,
}: {
  featureName: AccountPermissionFeatureName;
  domainName: AccountPermissionDomainName;
  createVal: boolean;
  readVal: boolean;
  updateVal: boolean;
  deleteVal: boolean;
}): AccountPermissions => {
  const accountPermissions = {
    __typename: 'AccountPermissions',
    __isNode: 'AccountPermissions',
    featurePermissions: [
      {
        feature: {
          accountPermissionFeatureName: featureName,
          domain: { id: '1', name: domainName },
          id: 'feature-1',
        },
        featureOperations: {
          create: createVal,
          delete: deleteVal,
          read: readVal,
          update: updateVal,
        },
        id: '1',
      },
    ],
  };
  return accountPermissions as unknown as AccountPermissions;
};
