import React, { useCallback } from 'react';

declare global {
  interface Window {
    // TODO: replace all usage of legacy $zopim API with zE.
    $zopim?: Zopim;
    zE?: ZendeskCommand;
  }
}

interface ZendeskChatProps {
  key?: string;
  enabled?: boolean;
}

interface ZendeskUser {
  name: string;
  email: string;
}

export type Status = 'online' | 'away' | 'offline';

type ZendeskCommand = (
  app: 'webWidget',
  command: 'identify',
  data: {
    name?: string;
    email?: string;
  }
) => void;

type Zopim = {
  livechat: {
    window: {
      onHide(callback: Function): void;
      onShow(callback: Function): void;
      show(): void;
    };
    button: {
      hide(): void;
    };
    setOnUnreadMsgs(callback: (count: number) => void): void;
    setOnStatus(callback: (status: Status) => void): void;
  };
} & Function;

const identifyUser = (user: ZendeskUser) => {
  if (!window.zE) {
    return;
  }

  window.zE('webWidget', 'identify', {
    name: user.name,
    email: user.email,
  });
};

export const useZendeskChat = ({ key, enabled = true }: ZendeskChatProps) => {
  const getZopim = () => window.$zopim;

  const [ready, setReady] = React.useState(false);
  const [user, setUser] = React.useState<ZendeskUser | null>(null);
  const [status, setStatus] = React.useState<Status>('offline');
  const [unreadCount, setUnreadCount] = React.useState(0);

  React.useEffect(() => {
    if (!enabled) return;
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
    script.onload = () => {
      // hack to get zopim since it's not immediately available
      const zopimReadyTimerId = setInterval(() => {
        const zopim = getZopim();
        let isOpen = false; // workaround to get state shared across methods
        if (!zopim) return;
        zopim(() => {
          // hide the default chat button
          zopim.livechat.button.hide();
          zopim.livechat.window.onHide(() => {
            // keep button hidden when user hide's the chat window
            zopim.livechat.button.hide();
            isOpen = false;
          });
          zopim.livechat.window.onShow(() => {
            setUnreadCount(0);
            isOpen = true;
          });
          // hook up event listeners
          zopim.livechat.setOnUnreadMsgs((count) => {
            if (isOpen) return;
            setUnreadCount(count);
          });
          zopim.livechat.setOnStatus((currentStatus) => setStatus(currentStatus));
          setReady(true);
        });
        clearInterval(zopimReadyTimerId);
      }, 100);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [enabled, key]);

  React.useEffect(() => {
    const zopim = getZopim();
    if (!zopim || !ready || !user) {
      return;
    }

    identifyUser(user);
  }, [ready, user]);

  const setCurrentUser = useCallback(
    (name: string, email: string) => {
      setUser({ name, email });
    },
    [setUser]
  );

  return {
    status,
    unreadCount,
    setCurrentUser,
    openChat() {
      const zopim = getZopim();
      if (!zopim || !enabled) return;
      zopim(() => {
        zopim.livechat.window.show();
      });
    },
  };
};
