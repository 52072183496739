import _useZendeskLoaderAcoreUtilsFragment_query from "./__generated__/useZendeskLoaderAcoreUtilsFragment_query.graphql";
import { Role, graphql } from '@attentive/data';
import { useZendeskChat } from './zendesk-chat';
import { atom, useSetAtom } from 'jotai';
import { useFragment } from 'react-relay';
import { useCompanyFeatureFlag } from '../feature-flags';
import { useRoles } from '../atoms';
import React from 'react';
import Fragment from './__generated__/useZendeskLoaderAcoreUtilsFragment_query.graphql';
_useZendeskLoaderAcoreUtilsFragment_query;
export const zendeskAtom = atom({
  status: 'offline',
  unreadCount: 0,
  setCurrentUser: () => {},
  openChat: () => {}
});
const ZENDESK_LIVECHAT_KEY = '8c45b94e-e2a2-4aee-a2c7-e98f24f33e77';
const ZENDESK_MESSAGING_KEY = 'c6517cb1-f444-42b9-80cf-a90e3bbc85c8';
export const useZendeskLoader = fragRef => {
  const fragData = useFragment(Fragment, fragRef);
  const setZendeskAtom = useSetAtom(zendeskAtom);
  const isZendeskDevModeEnabled = useCompanyFeatureFlag('ENABLE_ZENDESK_CHAT_DEVELOPER');
  const isZendeskMessagingEnabled = useCompanyFeatureFlag('ENABLE_ZENDESK_MESSAGING');
  const isAttentiveAccount = useRoles().has(Role.RoleAttentiveAccount);
  const isTestCompany = !fragData.company.csTier;
  const shouldEnableChat = isZendeskDevModeEnabled || !isAttentiveAccount && !isTestCompany;
  const key = isZendeskMessagingEnabled ? ZENDESK_MESSAGING_KEY : ZENDESK_LIVECHAT_KEY;
  const {
    status,
    unreadCount,
    openChat,
    setCurrentUser
  } = useZendeskChat({
    key,
    enabled: shouldEnableChat
  });
  React.useEffect(() => {
    setZendeskAtom({
      status,
      unreadCount,
      setCurrentUser,
      openChat
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    isEnabled: shouldEnableChat
  };
};