import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash/debounce';
import { CountryCode, getLocaleConfig } from '@attentive/locale-utils';

import { useCurrentUser } from '../current-user';

/**
 * Returns a function which when called with force a re-render
 *
 * Code copied from: https://stackoverflow.com/questions/53215285/how-can-i-force-component-to-re-render-with-hooks-in-react#53215514
 *
 * @private
 */
export const useForceUpdate = () => {
  const [, updateState] = useState<Record<string, unknown>>();
  return useCallback(() => updateState({}), []);
};

/**
 * Returns true if the ISO country code of the current user's company is GB
 *
 * @public
 * @deprecated — fetch the companies country code from GQL instead
 */
export const useIsCompanyInUk = () => {
  const { company } = useCurrentUser();
  return company.country === CountryCode.GB;
};

/**
 * Returns a value which will be debounced to only change every @param delay milliseconds.
 *
 * @param value Value to debounce
 * @param delay Delay in milliseconds
 */
export function useDebouncedValue<T>(value: T, delay = 0): T {
  const previousValue = useRef(value);
  const [current, setCurrent] = useState(value);
  const debouncedCallback = useMemo(() => debounce((val: T) => setCurrent(val), delay), [delay]);

  useEffect(() => {
    if (value !== previousValue.current) {
      debouncedCallback(value);
      previousValue.current = value;

      return debouncedCallback.cancel;
    }
  }, [value, debouncedCallback]);

  return current;
}

/**
 * Returns the LocaleConfig for the current user's company's country
 *
 * @returns LocaleConfig
 */
export const useLocaleConfig = () => {
  const { company } = useCurrentUser();
  return getLocaleConfig(company?.country as CountryCode);
};
