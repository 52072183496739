import { isEqual } from '@attentive/nodash';

import { logDatadogError } from '../Logger';
import { AppStore } from '../atoms';
import { accountPermissionAtom } from '../atoms/account-permission-atom';
import { fetchAccountPermissions_AcoreUtils_Query$rawResponse as QueryType } from './__generated__/fetchAccountPermissions_AcoreUtils_Query.graphql';

type AccountPayload = NonNullable<
  NonNullable<
    NonNullable<NonNullable<QueryType['viewer']>['account']>['companyAccountPermissions']
  >['payload']
>;

export type AccountPermissions = Extract<AccountPayload, { __typename: 'AccountPermissions' }>;
type DefaultError = Extract<AccountPayload, { __typename: 'DefaultStandardError' }>;

export const hydrateAccountPermissionAtoms = async (accountPermissionData: QueryType) => {
  const { viewer } = accountPermissionData;
  const permissionsPayload = viewer?.account?.companyAccountPermissions?.payload;

  let finalPermissions: AccountPermissions | null = null;

  // Handle error cases (we really only want not found in the future)
  if (permissionsPayload?.__typename === 'DefaultStandardError') {
    const errorPayload = permissionsPayload as unknown as DefaultError;

    // If not found, set permissions to null
    if (errorPayload.status === 'STANDARD_ERROR_STATUS_NOT_FOUND') {
      finalPermissions = null;
    } else {
      logDatadogError(
        'client-ui',
        new Error(`Account permissions query failed: ${errorPayload.message}`)
      );
      finalPermissions = null;
    }
  }
  // Handle valid account permissions
  else if (permissionsPayload?.__typename === 'AccountPermissions') {
    finalPermissions = permissionsPayload as AccountPermissions;
  }
  // Handle unexpected response
  else {
    logDatadogError('client-ui', new Error(`Account permissions query failed`));
    finalPermissions = null;
  }

  // Only set the atom if necessary
  if (!isEqual(accountPermissionAtom, finalPermissions)) {
    AppStore.store.set(accountPermissionAtom, finalPermissions);
  }
};
