import { atom, useAtomValue } from 'jotai';
import { AccountPermissions } from '../RoleBasedAccessControl';

/* For more information about this atom please see the following confluence documentation: 
https://attentivemobile.atlassian.net/wiki/spaces/tactical/pages/4694736925/Role+Based+Access+Control+RBAC
A null state indicates no explicit access has been set for an account/company pair 
*/
export const accountPermissionAtom = atom<AccountPermissions | null>(null);

export const useAccountPermissions = () => {
  return useAtomValue(accountPermissionAtom);
};
