import { Role } from '@attentive/data';
import { useRoles } from '../atoms';
import { useAccountPermissions } from '../atoms/account-permission-atom';
import {
  AccountPermissionDomainName,
  AccountPermissionFeatureName,
} from './__generated__/fetchAccountPermissions_AcoreUtils_fragment_query.graphql';
import { AccountPermissions } from './hydrateAccountPermissionAtoms';

export type AccessType = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';

export interface HasAccessRequest {
  featureName: AccountPermissionFeatureName;
  domainName: AccountPermissionDomainName;
  accessType: AccessType;
  fallback?: boolean;
  override?: Role[] | (() => boolean);
}

export interface HasAccessResponse {
  readAccess: boolean;
  createAccess: boolean;
  updateAccess: boolean;
  deleteAccess: boolean;
}

/*
This function will determine if an account has been granted access to a paticular section of the UI.
we will give the option to use certain account rolls as a pass through to maintain access for roles with 
elevated privledges in the company (Attentive employees and Admins) or a function if the need for something more complicated arrises
We will use the "fallback" value when: 
    - the account permission atom is null 
    - the feature is missing from the currently configured account permissions object 
*/
export const useHasAccessTo = (request: HasAccessRequest): boolean => {
  const roles = useRoles();
  const accountPermissions: AccountPermissions | null = useAccountPermissions();

  return hasAccess({ ...request, roles, accountPermissions });
};

export const useHasAccess = (request: Omit<HasAccessRequest, 'accessType'>): HasAccessResponse => {
  const roles = useRoles();
  const accountPermissions: AccountPermissions | null = useAccountPermissions();

  const hasFeatureAccess = (accessType: AccessType) => {
    return hasAccess({ ...request, accessType, roles, accountPermissions });
  };

  const [readAccess, createAccess, updateAccess, deleteAccess] = new Array<AccessType>(
    'READ',
    'CREATE',
    'UPDATE',
    'DELETE'
  ).map(hasFeatureAccess);

  return { readAccess, createAccess, updateAccess, deleteAccess };
};

function hasAccess({
  featureName,
  domainName,
  accessType,
  fallback = true,
  override = new Array<Role>(
    Role.RoleAdminAccess,
    Role.RoleAttentiveAccount,
    Role.RoleSuperUser,
    Role.RoleClientAdminAccount
  ),
  roles,
  accountPermissions,
}: HasAccessRequest & {
  roles: Set<Role>;
  accountPermissions: AccountPermissions | null;
}): boolean {
  let overrideForFeature = false;
  if (override) {
    if (override instanceof Array) {
      overrideForFeature = override.some((role) => roles.has(role));
    } else {
      overrideForFeature = override();
    }
  }

  if (overrideForFeature) return true;

  if (accountPermissions === null) return fallback;

  const feature = accountPermissions.featurePermissions.find(
    (featurePermission) =>
      domainName === featurePermission.feature?.domain?.name &&
      featureName === featurePermission.feature?.accountPermissionFeatureName
  );

  if (!feature) {
    return fallback;
  }
  let featurePermissionValue = false;
  switch (accessType) {
    case 'CREATE':
      featurePermissionValue = Boolean(feature.featureOperations.create);
      break;
    case 'READ':
      featurePermissionValue = Boolean(feature.featureOperations.read);
      break;
    case 'UPDATE':
      featurePermissionValue = Boolean(feature.featureOperations.update);
      break;
    case 'DELETE':
      featurePermissionValue = Boolean(feature.featureOperations.delete);
      break;
    default:
      featurePermissionValue = false;
      break;
  }

  return featurePermissionValue;
}
