/**
 * @generated SignedSource<<c02570dad031dfc05d8d29f81b50ff51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ZendeskLiveChatAcoreUtilsFragment_query$data = {
  readonly viewer: {
    readonly account: {
      readonly email: string;
      readonly firstName: string;
      readonly lastName: string;
    };
  };
  readonly " $fragmentType": "ZendeskLiveChatAcoreUtilsFragment_query";
};
export type ZendeskLiveChatAcoreUtilsFragment_query$key = {
  readonly " $data"?: ZendeskLiveChatAcoreUtilsFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ZendeskLiveChatAcoreUtilsFragment_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ZendeskLiveChatAcoreUtilsFragment_query",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "account",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            "action": "THROW",
            "path": "viewer.account"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "viewer"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0da6270d071249403850fbf70ccb8bbd";

export default node;
